<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="w-full">
        <div class="d-flex align-center">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="me-2 mx-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <!-- Right Content -->
          <theme-switcher></theme-switcher>
          <v-btn
            icon
            small
            class="ms-3"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>
          <app-bar-user-menu class="me-2 mx-2"></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main :key="$router.currentRoute.name">
      <div>
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
    >
      <div class=" w-full">
        <div class="mx-3 d-flex justify-space-between">
          <span>
            &copy; 2023 - {{this_year}}<a
            href="https://www.facebook.com/goodlife.itd/"
            class="text-decoration-none"
            target="_blank"
          > GOODLIFE IT DEPARTMENT</a></span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
  import {ref} from '@vue/composition-api'
  import {mdiMagnify, mdiBellOutline, mdiGithub} from '@mdi/js'
  import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
  import ThemeSwitcher from './components/ThemeSwitcher.vue'
  import AppBarUserMenu from './components/AppBarUserMenu.vue'
  import moment from "moment";
  import {mapGetters, mapActions} from 'vuex'

  export default {
    components: {
      VerticalNavMenu,
      ThemeSwitcher,
      AppBarUserMenu,
    },
    setup() {
      const isDrawerOpen = ref(null)

      return {
        isDrawerOpen,
        this_year: moment().format('YYYY'),
        // Icons
        icons: {
          mdiMagnify,
          mdiBellOutline,
          mdiGithub,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-app-bar ::v-deep {
    .v-toolbar__content {
      padding: 0;

      .app-bar-search {
        .v-input__slot {
          padding-left: 18px;
        }
      }
    }
  }

  .boxed-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
