import axios from 'axios'

axios.defaults.withCredentials = true

const actions = {
  create_request_data_v2({ rootGetters }, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .post('api/create_request_data_v2', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  delete_request_data_v2({ rootGetters }, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .post('api/delete_request_data_v2', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  create_customize_voucher_v2({ rootGetters }, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .post('api/create_customize_voucher_v2', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  request_is_credited_v2({ rootGetters }, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .post('api/request_is_credited_v2', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
}
