import axios from 'axios'

axios.defaults.withCredentials = true

const actions = {
  register_request_data({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/register-request-data', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete_request_data({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/delete-request-data', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  check_revolving_fund_availability({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/check-revolving-fund-availability', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  check_bills_availability({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/check_bills_availability', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  check_stuff_house_under_branch_expense_availability({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/check-stuff-house-under-branch-expense-availability', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  check_incentives_availability({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/check_incentives_availability', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_year_and_month_of({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-year-and-month-of', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  check_under_particulars_availability({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/check-under-particulars-availability', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_casket({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-casket', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_pending_claims({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-pending-claims', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initialization_of_request_payment_of_tie_up({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/initialization-of-request-payment-of-tie-up', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initialization_of_request_bod_allowance({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/initialization-of-request-bod-allowance', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  released_bod_allowance({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/released-bod-allowance', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_plan_type({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-plan-type', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  check_stuff_house_availability({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/check-stuff-house-availability', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_pending_auto_loans({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-pending-auto-loans', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_outreach_program({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-outreach-program', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create_customize_voucher({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/create-customize-voucher', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initialize_voucher_maintenance({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/initialize-voucher-maintenance', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initialize_edit_request_data({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/initialize-edit-request_data', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  search_of_cash_advance({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/search-of-cash-advance', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  change_ca_to_bod_charge({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/change-ca-to-bod-charge', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  change_ca_particulars({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/change-ca-particulars', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_cash_advance({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-cash-advance', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_cash_advance_bod_charges({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-cash-advance-bod-charges', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  search_of_cash_advance_bod_charges({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/search-of-cash-advance-bod-charges', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initialize_pdc_monitoring({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/initialize-pdc-monitoring', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  search_pdc_monitoring({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/search-pdc-monitoring', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_business_permit({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-business-permit', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_particulars_have_voucher({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-particulars-have-voucher', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_year_and_month_of_of_particular({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-year-and-month-of-of-particular', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  remove_request_from_voucher({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/remove-request-from-voucher', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_year_and_month_of_of_particular_office_rental({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-year-and-month-of-of-particular-office-rental', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  bills_monitoring({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/bills-monitoring', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  check_under_particulars_availability_have_branch({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/check-under-particulars-availability-have-branch', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  check_payment_for_tie_up_availability({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/check-payment-for-tie-up-availability', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  damayan_incentives_monitoring_details({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/damayan-incentives-monitoring-details', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  request_is_credited({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/request-is-credited', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  request_is_credited_all({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/request_is_credited_all', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
}
