import axios from 'axios'

axios.defaults.withCredentials = true

const actions = {
  initialize_branch({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/initialize-branch', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  branch_data({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/branch-data', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  register_new_branch({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/register-new-branch', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_branch_that_have_service({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-branch-that-have-service', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_branch_that_have_claims({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-branch-that-have-claims', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_department({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-department', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_regions({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list-of-regions', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update_minimum_wage({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/update-minimum-wage', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update_branch_address({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/update-branch-address', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
}
