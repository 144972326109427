import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCarousel from 'vue-carousel'
import VueCroppie from 'vue-croppie'
import CanvasJSChart from '@canvasjs/vue-charts';
import 'croppie/croppie.css' // import the croppie css manually

Vue.use(CanvasJSChart)
Vue.use(VueCroppie)
Vue.use(VueCarousel)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title

  const reqAuth = to.matched.some(record => record.meta.requiresAuth)
  const reqVisitor = to.matched.some(record => record.meta.requiresVisitor)

  const reqAdmin = to.matched.some(record => record.meta.requiresAdmin)
  const requiresConfidential = to.matched.some(record => record.meta.requiresConfidential)
  const requiresTreasury = to.matched.some(record => record.meta.requiresTreasury)
  const requiresAccounting = to.matched.some(record => record.meta.requiresAccounting)
  const requiresAMD = to.matched.some(record => record.meta.requiresAMD)
  const requiresProperty = to.matched.some(record => record.meta.requiresProperty)
  const requiresHR = to.matched.some(record => record.meta.requiresHR)
  const requiresBookKeeping = to.matched.some(record => record.meta.requiresBookKeeping)
  const requiresCMD = to.matched.some(record => record.meta.requiresCMD)
  const requiresExecutive = to.matched.some(record => record.meta.requiresExecutive)
  const requiresAudit = to.matched.some(record => record.meta.requiresAudit)
  const requiresEmployee = to.matched.some(record => record.meta.requiresEmployee)
  const requiresIT = to.matched.some(record => record.meta.requiresIT)
  const requiresFinance = to.matched.some(record => record.meta.requiresFinance)
  const requiresChapelAudit = to.matched.some(record => record.meta.requiresChapelAudit)
  const requiresLegal = to.matched.some(record => record.meta.requiresLegal)
  const requiresArAp = to.matched.some(record => record.meta.requiresArAp)

  const loginQuery = {path: '/'}
  const dashboard = {path: '/dashboard'}
  const not_found = {path: '/error-404'}

  if (store.getters['authentication/authenticated']) {
    if (reqAuth) {
      var proceed = false
      if (requiresConfidential) {
        if (store.getters['authentication/position'] === 'ADMIN' && ((store.getters['authentication/employee_id'] === 1) || (store.getters['authentication/employee_id'] === 2)|| (store.getters['authentication/employee_id'] === 3))) {
          proceed = true
        }
      }

      if (reqAdmin) {
        if (store.getters['authentication/position'] === 'ADMIN') {
          proceed = true
        }
      }

      if (requiresTreasury) {
        if (store.getters['authentication/position'] === 'TREASURY') {
          proceed = true
        }
      }

      if (requiresAccounting) {
        if (store.getters['authentication/position'] === 'ACCOUNTING') {
          proceed = true
        }
      }
      if (requiresAMD) {
        if (store.getters['authentication/position'] === 'AMD') {
          proceed = true
        }
      }
      if (requiresProperty) {
        if (store.getters['authentication/position'] === 'PROPERTY CUSTODIAN') {
          proceed = true
        }
      }
      if (requiresHR) {
        if (store.getters['authentication/position'] === 'HUMAN RESOURCE') {
          proceed = true
        }
      }
      if (requiresBookKeeping) {
        if (store.getters['authentication/position'] === 'BOOKKEEPING') {
          proceed = true
        }
      }
      if (requiresCMD) {
        if (store.getters['authentication/position'] === 'CMD') {
          proceed = true
        }
      }
      if (requiresExecutive) {
        if (store.getters['authentication/position'] === 'EXECUTIVE') {
          proceed = true
        }
      }
      if (requiresAudit) {
        if (store.getters['authentication/position'] === 'AUDIT') {
          proceed = true
        }
      }
      if (requiresEmployee) {
        if (store.getters['authentication/position'] === 'EMPLOYEE') {
          proceed = true
        }
      }
      if (requiresIT) {
        if (store.getters['authentication/position'] === 'IT') {
          proceed = true
        }
      }
      if (requiresFinance) {
        if (store.getters['authentication/position'] === 'FINANCE') {
          proceed = true
        }
      }
      if (requiresChapelAudit) {
        if (store.getters['authentication/position'] === 'CHAPEL AUDIT') {
          proceed = true
        }
      }
      if (requiresLegal) {
        if (store.getters['authentication/position'] === 'LEGAL') {
          proceed = true
        }
      }
      if (requiresArAp) {
        if (store.getters['authentication/position'] === 'AR/AP') {
          proceed = true
        }
      }

      //reqAuth Only
      if (
        !reqAdmin &&
        !requiresConfidential &&
        !requiresTreasury &&
        !requiresAccounting &&
        !requiresAMD &&
        !requiresProperty &&
        !requiresHR &&
        !requiresBookKeeping &&
        !requiresCMD &&
        !requiresExecutive &&
        !requiresAudit &&
        !requiresEmployee &&
        !requiresIT &&
        !requiresFinance &&
        !requiresLegal &&
        !requiresChapelAudit &&
        !requiresArAp
      ) {
        proceed = true
      }

      if (proceed) {
        next()
      } else {
        next(not_found)
      }
    } else if (reqVisitor) {
      next(dashboard)
    } else {
      next()
    }
  } else {
    if (reqAuth) {
      next(loginQuery)
    } else {
      next()
    }
  }
})

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    switch (error.response.status) {
      case 401: // Not logged in
      case 419: // Session expired
      case 503: // Down for maintenance
        // Bounce the user to the login screen with a redirect back
        if (store.getters['authentication/authenticated']) {
          store.commit('authentication/set_authenticated', false)
          store.dispatch(
            'authentication/force_logout_credentials',
            {id_no: store.getters['authentication/id_no']},
            {root: true},
          )
        }
        router.push({path: '/'})
        break
      case 500:
        return Promise.reject(error)
        break
      default:
        // Allow individual requests to handle other errors
        return Promise.reject(error)
    }
  },
)
