import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate';
import system_data from './module/system'
import form_rules from './module/form_rules'
import authentication from './module/authentication'
import users from './module/user'
import transaction_months from './module/data/transaction_months'
import bank_depository from './module/data/bank_depository'
import branch_information from './module/data/branch_information'
import employee from './module/data/employee'
import salaries_and_wages from './module/data/salaries_and_wages'
import employee_compensation_history from './module/data/employee_compensation_history'
import employee_change_position_branch_history
  from './module/data/employee_change_position_branch_history'
import request_particulars from './module/data/request_particulars'
import request from './module/data/request'
import affiliatee from './module/data/affiliate'
import request_data from './module/data/request_data'
import tie_ups from './module/data/tie_ups'
import loan_application from './module/data/loan_application'
import inventory from './module/data/inventory'
import category from './module/data/category'
import thirten_month_pay from './module/data/thirten_month_pay'
import motor_finance from './module/data/motor_finance'
import other_receivables from './module/data/other_receivables'
import auto_loan from './module/data/auto_loan'
import salary_deductions from './module/data/salary_deductions'
import monthly_liquidation from './module/data/monthly_liquidation'
import bank_transaction from './module/data/bank_transaction'
import cheque_book_series from './module/data/cheque_book_series'
import cash_vouchers from './module/data/cash_vouchers'
import cheque_vouchers from './module/data/cheque_vouchers'
import fund_transfer_vouchers from './module/data/fund_transfer_vouchers'
import cheque_book_series_pads from './module/data/cheque_book_series_pads'
import cheque_book_series_canceled from './module/data/cheque_book_series_canceled'
import deposit_slip_receivable from './module/data/deposit_slip_receivable'
import bod_annual_share from './module/data/bod_annual_share'
import bod_annual_share_chapel from './module/data/bod_annual_share_chapel'
import business_permit_released_fund from './module/data/business_permit_released_fund'
import business_permit_liquidation from './module/data/business_permit_liquidation'
import business_permit_return_fund from './module/data/business_permit_return_fund'
import office_rental_security_deposits from './module/data/office_rental_security_deposits'
import bod_allowances from './module/data/bod_allowances'
import bod_allowances_deductions from './module/data/bod_allowances_deductions'
import uploaded_cheque from './module/data/uploaded_cheque'
import auto_maintenance_details from './module/data/auto_maintenance_details'
import company_inventory from './module/data/company_inventory'
import inventory_particulars from './module/data/inventory_particulars'
import start_new_month from './module/data/start_new_month'
import employee_memo_history from './module/data/employee_memo_history'
import tie_up_csp_rate from './module/data/tie_up_csp_rate'
import auto_maintenance from './module/data/auto_maintenance'
import inventory_history from './module/data/inventory_history'
import affiliate_history from './module/data/affiliate_history'
import list_of_service from './module/data/list_of_service'
import list_of_claims from './module/data/list_of_claims'
import uploaded_memos from './module/data/uploaded_memos'
import employee_position from './module/data/employee_position'
import inventory_payment from './module/data/inventory_payment'
import palawan_transactions from './module/data/palawan_transactions'
import employee_evaluation from './module/data/employee_evaluation'
import employee_evaluation_status from './module/data/employee_evaluation_status'
import employee_evaluation_data from './module/data/employee_evaluation_data'
import borrower_information from './module/data/borrower_information'
import ggc_loans from './module/data/ggc_loans'
import particulars_mains from './module/data/particulars_mains'
import request_v2_s from './module/data/request_v2_s'
import request_v2_data from './module/data/request_v2_data'
import cash_voucher_v2_s from './module/data/cash_voucher_v2_s'
import cheque_voucher_v2_s from './module/data/cheque_voucher_v2_s'
import fund_transfer_voucher_v2_s from './module/data/fund_transfer_voucher_v2_s'
import uploaded_cheque_v2_s from './module/data/uploaded_cheque_v2_s'
import monthly_liquidation_v2_s from './module/data/monthly_liquidation_v2_s'
import ggc_loan_deposit_slips from './module/data/ggc_loan_deposit_slips'
import ggc_loan_payments from './module/data/ggc_loan_payments'
import fourteen_month_pay from './module/data/fourteen_month_pay'
import allocation_of_funds from './module/data/allocation_of_funds'
import allocation_of_fund_data from './module/data/allocation_of_fund_data'
import allocation_of_fund_data_schedule from './module/data/allocation_of_fund_data_schedule'
import monthly_liquidation_cheque from './module/data/monthly_liquidation_cheque'

const ls = new SecureLS({
  isCompression: false,
})
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState(
    {
      key: 'GGC-HO',
      paths: ['authentication'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    },
  )],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    system_data,
    form_rules,
    authentication,
    users,
    transaction_months,
    bank_depository,
    branch_information,
    employee,
    salaries_and_wages,
    employee_compensation_history,
    employee_change_position_branch_history,
    request_particulars,
    request,
    affiliatee,
    request_data,
    tie_ups,
    loan_application,
    inventory,
    category,
    thirten_month_pay,
    motor_finance,
    other_receivables,
    auto_loan,
    salary_deductions,
    monthly_liquidation,
    bank_transaction,
    cheque_book_series,
    cash_vouchers,
    cheque_vouchers,
    fund_transfer_vouchers,
    cheque_book_series_pads,
    cheque_book_series_canceled,
    deposit_slip_receivable,
    bod_annual_share,
    bod_annual_share_chapel,
    business_permit_released_fund,
    business_permit_liquidation,
    business_permit_return_fund,
    office_rental_security_deposits,
    bod_allowances,
    bod_allowances_deductions,
    uploaded_cheque,
    auto_maintenance_details,
    company_inventory,
    inventory_particulars,
    start_new_month,
    employee_memo_history,
    tie_up_csp_rate,
    auto_maintenance,
    inventory_history,
    affiliate_history,
    list_of_service,
    list_of_claims,
    uploaded_memos,
    employee_position,
    inventory_payment,
    palawan_transactions,
    employee_evaluation,
    employee_evaluation_status,
    employee_evaluation_data,
    borrower_information,
    ggc_loans,
    particulars_mains,
    request_v2_s,
    request_v2_data,
    cash_voucher_v2_s,
    cheque_voucher_v2_s,
    fund_transfer_voucher_v2_s,
    uploaded_cheque_v2_s,
    monthly_liquidation_v2_s,
    ggc_loan_deposit_slips,
    ggc_loan_payments,
    fourteen_month_pay,
    allocation_of_funds,
    allocation_of_fund_data,
    allocation_of_fund_data_schedule,
    monthly_liquidation_cheque,
  },
})
