import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'pages/login',
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error.vue'),
    meta: {
      layout: 'blank',
      title: '404|Not Found',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/loan-division',
    name: 'loan-division',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/accounting',
    name: 'accounting',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/amd',
    name: 'amd',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/audit',
    name: 'audit',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/bookkeeping',
    name: 'bookkeeping',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/cmd',
    name: 'cmd',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/employee',
    name: 'employee',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/executive',
    name: 'executive',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/human-resource',
    name: 'human-resource',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/it',
    name: 'it',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/property-custodian',
    name: 'property-custodian',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/treasury',
    name: 'treasury',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/chapel-audit',
    name: 'chapel-audit',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login',
      requiresVisitor: true,
    },
  },
  {
    path: '/new-employee',
    name: 'new-employee',
    component: () => import('@/views/file_interface/register/NewEmployee'),
    meta: {
      title: 'New Employee',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
    },
  },
  {
    path: '/pending-employee',
    name: 'pending-employee',
    component: () => import('@/views/view_interface/employee/PendingEmployee'),
    meta: {
      title: 'Pending Employee',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
    },
  },
  {
    path: '/salaries-and-wages',
    name: 'salaries-and-wages',
    component: () => import('@/views/input_interface/salary_and_thirten_pay/salaries_wages/SalariesAndWages'),
    meta: {
      title: 'Salaries & Wages',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
    },
  },
  {
    path: '/salaries-and-wages-working-days',
    name: 'salaries-and-wages-working-days',
    component: () => import('@/views/input_interface/salary_and_thirten_pay/salaries_wages/SalaryAndWagesWorkingDays'),
    meta: {
      title: 'Salaries & Wages Working Days',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
    },
  },
  {
    path: '/chapel-payroll',
    name: 'chapel-payroll',
    component: () => import('@/views/input_interface/salary_and_thirten_pay/chapel_incentives/Chapelncentives'),
    meta: {
      title: 'Chapel Incentives',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
    },
  },
  {
    path: '/thirten-pay',
    name: 'thirten-pay',
    component: () => import('@/views/input_interface/salary_and_thirten_pay/thirten_pay/ThirtenMonthPay'),
    meta: {
      title: '13th Month Pay',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
    },
  },
  {
    path: '/fourteen-pay',
    name: 'fourteen-pay',
    component: () => import('@/views/input_interface/salary_and_thirten_pay/fourteen_month_pay/FourteenMonthPay'),
    meta: {
      title: '14th Month Pay',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
    },
  },
  {
    path: '/list-employee',
    name: 'list-employee',
    component: () => import('@/views/view_interface/employee/ListOfEmployee'),
    meta: {
      title: 'List of Employee',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
      requiresExecutive: true,
    },
  },
  {
    path: '/list-maternity-employee',
    name: 'list-maternity-employee',
    component: () => import('@/views/view_interface/employee/ListOfMaternityEmployee'),
    meta: {
      title: 'List of Maternity Employee',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
      requiresExecutive: true,
    },
  },
  {
    path: '/salary-increase-report',
    name: 'salary-increase-report',
    component: () => import('@/views/view_interface/employee/SalaryIncreaseReport'),
    meta: {
      title: 'Salary Increase Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
    },
  },
  {
    path: '/payroll-report',
    name: 'payroll-report',
    component: () => import('@/views/view_interface/salaries_wages/Payroll'),
    meta: {
      title: 'Payroll',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresArAp: true,
      requiresCMD: true,
      requiresHR: true,
      requiresExecutive: true,
    },
  },
  {
    path: '/incentives-payroll-report',
    name: 'incentives-payroll-report',
    component: () => import('@/views/view_interface/salaries_wages/IncentivesPayroll'),
    meta: {
      title: 'Incentives Payroll',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
      requiresExecutive: true,
    },
  },
  {
    path: '/change-branch-position-report',
    name: 'change-branch-position-report',
    component: () => import('@/views/view_interface/employee/ChangeBranchAndPositionReport'),
    meta: {
      title: 'Change Branch And Position Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresExecutive: true,
      requiresHR: true,
    },
  },
  {
    path: '/change-bank-info-report',
    name: 'change-bank-info-report',
    component: () => import('@/views/view_interface/employee/ChangeBankInfoReport'),
    meta: {
      title: 'Change Bank Info Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresExecutive: true,
      requiresHR: true,
    },
  },
  {
    path: '/new-bank-depositories',
    name: 'new-bank-depositories',
    component: () => import('@/views/file_interface/register/NewBankDepository'),
    meta: {
      title: 'New Bank Depository',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/new-branch',
    name: 'new-branch',
    component: () => import('@/views/file_interface/register/NewBranch'),
    meta: {
      title: 'New Branch',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-particulars',
    name: 'new-particulars',
    component: () => import('@/views/file_interface/register/NewParticulars'),
    meta: {
      title: 'New Particulars',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-bills-info',
    name: 'new-bills-info',
    component: () => import('@/views/file_interface/register/NewBillsInfo'),
    meta: {
      title: 'New Bills Info',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/new-regular-request',
    name: 'new-regular-request',
    component: () => import('@/views/file_interface/create/request/RegularRequest'),
    meta: {
      title: 'New Regular Request',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresAMD: true,
      requiresProperty: true,
      requiresEmployee: true,
      requiresHR: true,
      requiresExecutive: true,
      requiresFinance: true,
      requiresChapelAudit: true,
      requiresLegal: true,
      requiresBookKeeping: true,
    },
  },
  {
    path: '/new-pdc-request',
    name: 'new-pdc-request',
    component: () => import('@/views/file_interface/create/request/PDCRequest'),
    meta: {
      title: 'New PDC Request',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresAMD: true,
      requiresProperty: true,
      requiresHR: true,
      requiresExecutive: true,
    },
  },
  {
    path: '/new-ftv-request',
    name: 'new-ftv-request',
    component: () => import('@/views/file_interface/create/request/FTVRequest'),
    meta: {
      title: 'New FTV Request',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
    },
  },
  {
    path: '/new-regular-request-v2',
    name: 'new-regular-request-v2',
    component: () => import('@/views/file_interface/create/request_v2/RegularRequestV2'),
    meta: {
      title: 'New Regular Request V2',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresAMD: true,
      requiresProperty: true,
      requiresEmployee: true,
      requiresHR: true,
      requiresExecutive: true,
      requiresFinance: true,
      requiresChapelAudit: true,
      requiresLegal: true,
    },
  },
  {
    path: '/new-pdc-request-v2',
    name: 'new-pdc-request-v2',
    component: () => import('@/views/file_interface/create/request_v2/PDCRequestV2'),
    meta: {
      title: 'New PDC Request V2',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresAMD: true,
      requiresProperty: true,
      requiresHR: true,
      requiresExecutive: true,
    },
  },
  {
    path: '/new-ftv-request-v2',
    name: 'new-ftv-request-v2',
    component: () => import('@/views/file_interface/create/request_v2/FTVRequestV2'),
    meta: {
      title: 'New FTV Request V2',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
    },
  },
  // {
  //   path: '/new-motor-finance',
  //   name: 'new-motor-finance',
  //   component: () => import('@/views/file_interface/create/MotorFinance'),
  //   meta: {
  //     title: 'Motor Finance',
  //     requiresAuth: true,
  //     requiresAdmin: true,
  //     requiresTreasury: true,
  //   },
  // },
  {
    path: '/new-other-receivable',
    name: 'new-other-receivable',
    component: () => import('@/views/file_interface/create/OtherReceivable'),
    meta: {
      title: 'Other Receivable',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresArAp: true,
    },
  },
  // {
  //   path: '/new-personal-loan',
  //   name: 'new-personal-loan',
  //   component: () => import('@/views/file_interface/create/loan/PersonalLoan'),
  //   meta: {
  //     title: 'Personal Loan',
  //     requiresAuth: true,
  //     requiresAdmin: true,
  //   },
  // },
  {
    path: '/new-ggc-loan',
    name: 'new-ggc-loan',
    component: () => import('@/views/ggc_loan_interface/GGCLoan'),
    meta: {
      title: 'GGC Loan',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/ggc-loan-payment',
    name: 'ggc-loan-payment',
    component: () => import('@/views/ggc_loan_interface/payment_interface/MainInterface'),
    meta: {
      title: 'GGC Loan Payment',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/ggc-loan-remittances',
    name: 'ggc-loan-remittances',
    component: () => import('@/views/ggc_loan_interface/RemittancesReport'),
    meta: {
      title: 'GGC Loan Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/list-of-ggc-loan',
    name: 'list-of-ggc-loan',
    component: () => import('@/views/ggc_loan_interface/report_interface/ListOfGGCLoan'),
    meta: {
      title: 'List Of GGC Loan',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/monthly-due-ggc-loan',
    name: 'monthly-due-ggc-loan',
    component: () => import('@/views/ggc_loan_interface/report_interface/MontlyDueReport'),
    meta: {
      title: 'Monthly Due Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/monthly-deductions-ggc-loan',
    name: 'monthly-deductions-ggc-loan',
    component: () => import('@/views/ggc_loan_interface/report_interface/MontlyDeductionReport'),
    meta: {
      title: 'Monthly Deduction Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/transmital-ggc-loan',
    name: 'transmital-ggc-loan',
    component: () => import('@/views/ggc_loan_interface/report_interface/TransmitalDeductionReport'),
    meta: {
      title: 'Transmital Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/monthly-summary-deductions-ggc-loan',
    name: 'monthly-summary-deductions-ggc-loan',
    component: () => import('@/views/ggc_loan_interface/report_interface/MontlySummaryDeductionReport'),
    meta: {
      title: 'Monthly Summary Deduction Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/monthly-collection-ggc-loan',
    name: 'monthly-collection-ggc-loan',
    component: () => import('@/views/ggc_loan_interface/report_interface/MontlyCollectionReport'),
    meta: {
      title: 'Montly Collection Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/delinquent-account-ggc-loan',
    name: 'delinquent-account-ggc-loan',
    component: () => import('@/views/ggc_loan_interface/report_interface/ListOfDeliquentAccount'),
    meta: {
      title: 'Delinquent Account Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/new-auto-loan',
    name: 'new-auto-loan',
    component: () => import('@/views/file_interface/create/loan/AutoLoan'),
    meta: {
      title: 'Auto Loan',
      requiresAuth: true,
      requiresAdmin: true,
      requiresArAp: true,
    },
  },
  {
    path: '/search-employee',
    name: 'search-employee',
    component: () => import('@/views/file_interface/search/SearchEmployee'),
    meta: {
      title: 'Search Employee',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
      requiresHR: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresArAp: true,
      requiresCMD: true,
      requiresBookKeeping: true,
      requiresExecutive: true,
      requiresLegal: true,
    },
  },
  {
    path: '/13-month-payroll-report',
    name: '13-month-payroll-report',
    component: () => import('@/views/view_interface/salaries_wages/ThirtenMonthPayroll'),
    meta: {
      title: '13th Month Payroll',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
    },
  },
  {
    path: '/14-month-payroll-report',
    name: '14-month-payroll-report',
    component: () => import('@/views/view_interface/salaries_wages/FourteenMonthPayroll'),
    meta: {
      title: '14th Month Payroll',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
    },
  },
  {
    path: '/salary-deductions',
    name: 'salary-deductions',
    component: () => import('@/views/input_interface/salary_and_thirten_pay/salaries_wages/SalaryDeductions'),
    meta: {
      title: 'Salary Deductions',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
      requiresAudit: true,
    },
  },
  {
    path: '/13-month-monitoring',
    name: '13-month-monitoring',
    component: () => import('@/views/view_interface/salaries_wages/ThirtenMonthMonitoring'),
    meta: {
      title: '13th Month Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
    },
  },
  {
    path: '/14-month-monitoring',
    name: '14-month-monitoring',
    component: () => import('@/views/view_interface/salaries_wages/FourteenMonthMonitoring'),
    meta: {
      title: '14th Month Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
      requiresFinance: true,
    },
  },
  {
    path: '/bir_monitoring-alpha-list',
    name: 'bir_monitoring-alpha-list',
    component: () => import('@/views/view_interface/monitoring/bir_monitoring/BIRAlphaList'),
    meta: {
      title: 'BIR Alpha List',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBookKeeping: true,
    },
  },
  {
    path: '/bir_monitoring-monthly-gov-benefits',
    name: 'bir_monitoring-monthly-gov-benefits',
    component: () => import('@/views/view_interface/monitoring/bir_monitoring/BIRMonthlyGovBenefits'),
    meta: {
      title: 'BIR Monthly Gov. Benefits',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBookKeeping: true,
    },
  },
  {
    path: '/ml-regular-expense',
    name: 'ml-regular-expense',
    component: () => import('@/views/input_interface/monthly_liquidation/MLRegularExpense'),
    meta: {
      title: 'Monthly Liquidation - Regular Expense',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresBookKeeping: true,
    },
  },
  {
    path: '/ml-cheque-expense',
    name: 'ml-cheque-expense',
    component: () => import('@/views/input_interface/monthly_liquidation/MLChequeExpense'),
    meta: {
      title: 'Monthly Liquidation - Cheque Expense',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/ml-regular-expense-v2',
    name: 'ml-regular-expense-v2',
    component: () => import('@/views/input_interface/monthly_liquidation_v2/MLRegularExpense'),
    meta: {
      title: 'Monthly Liquidation V2 - Regular Expense',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/ml-cash-advance-expense',
    name: 'ml-cash-advance-expense',
    component: () => import('@/views/input_interface/monthly_liquidation/MLCashAdvanceExpense'),
    meta: {
      title: 'Monthly Liquidation - Cash Advance Expense',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresArAp: true,
      requiresCMD: true,
    },
  },
  {
    path: '/ml-cash-advance-expense-v2',
    name: 'ml-cash-advance-expense-v2',
    component: () => import('@/views/input_interface/monthly_liquidation_v2/MLCashAdvanceExpense'),
    meta: {
      title: 'Monthly Liquidation V2 - Cash Advance Expense',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/ml-cash-advance-expense-ckv',
    name: 'ml-cash-advance-expense-ckv',
    component: () => import('@/views/input_interface/monthly_liquidation/MLCashAdvanceExpense'),
    meta: {
      title: 'Monthly Liquidation - Cash Advance Expense',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresArAp: true,
      requiresCMD: true,
    },
  },
  {
    path: '/ml-cash-advance-expense-ckv-v2',
    name: 'ml-cash-advance-expense-ckv-v2',
    component: () => import('@/views/input_interface/monthly_liquidation_v2/MLCashAdvanceExpense'),
    meta: {
      title: 'Monthly Liquidation V2 - Cash Advance Expense',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/bank-transaction-history',
    name: 'bank-transaction-history',
    component: () => import('@/views/view_interface/BankTransactionHistory'),
    meta: {
      title: 'Bank Transaction History',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
    },
  },
  {
    path: '/remittances-transaction-history',
    name: 'remittances-transaction-history',
    component: () => import('@/views/view_interface/RemittancesTransactionHistory'),
    meta: {
      title: 'Remittances Transaction History',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
    },
  },
  {
    path: '/cash-flow',
    name: 'cash-flow',
    component: () => import('@/views/input_interface/cash_flow/CashFlow'),
    meta: {
      title: 'Cash Flow',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
    },
  },
  {
    path: '/remittances-cash-flow',
    name: 'remittances-cash-flow',
    component: () => import('@/views/input_interface/cash_flow/palawan/PalawanCashFlow'),
    meta: {
      title: 'Remittances Cash Flow',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
    },
  },
  {
    path: '/create-cash-voucher',
    name: 'create-cash-voucher',
    component: () => import('@/views/input_interface/vouchers/CashVoucher'),
    meta: {
      title: 'Cash Voucher',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/create-cheque-voucher',
    name: 'create-cheque-voucher',
    component: () => import('@/views/input_interface/vouchers/ChequeVoucher'),
    meta: {
      title: 'Cheque Voucher',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
      requiresAMD: true,
    },
  },
  {
    path: '/create-fund-transfer-voucher',
    name: 'create-fund-transfer-voucher',
    component: () => import('@/views/input_interface/vouchers/FundTransferVoucher'),
    meta: {
      title: 'Fund Transfer Voucher',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/create-cash-voucher-v2',
    name: 'create-cash-voucher-v2',
    component: () => import('@/views/input_interface/vouchers_v2/CashVoucherV2'),
    meta: {
      title: 'Cash Voucher',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/create-cheque-voucher-v2',
    name: 'create-cheque-voucher-v2',
    component: () => import('@/views/input_interface/vouchers_v2/ChequeVoucherV2'),
    meta: {
      title: 'Cheque Voucher',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
      requiresAMD: true,
    },
  },
  {
    path: '/create-fund-transfer-voucher-v2',
    name: 'create-fund-transfer-voucher-v2',
    component: () => import('@/views/input_interface/vouchers_v2/FundTransferVoucherV2'),
    meta: {
      title: 'Fund Transfer Voucher V2',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/voucher-maintenance',
    name: 'voucher-maintenance',
    component: () => import('@/views/input_interface/VoucherMaintenance'),
    meta: {
      title: 'Voucher Maintenance',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-cheque-book-voucher-supply',
    name: 'new-cheque-book-voucher-supply',
    component: () => import('@/views/file_interface/cheque_book/VoucherSupply'),
    meta: {
      title: 'Voucher Supply',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/new-cheque-book-accounting-supply',
    name: 'new-cheque-book-accounting-supply',
    component: () => import('@/views/file_interface/cheque_book/AccountingSupply'),
    meta: {
      title: 'Accounting Supply',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/cheque-book-monitoring-over-all',
    name: 'cheque-book-monitoring-over-all',
    component: () => import('@/views/view_interface/monitoring/cheque_book_monitoring/OverAll'),
    meta: {
      title: 'Cheque Book Monitoring - Overall',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/cheque-book-monitoring-supply',
    name: 'cheque-book-monitoring-supply',
    component: () => import('@/views/view_interface/monitoring/cheque_book_monitoring/Supply'),
    meta: {
      title: 'Cheque Book Monitoring - Supply',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/cheque-book-monitoring-canceled',
    name: 'cheque-book-monitoring-canceled',
    component: () => import('@/views/view_interface/monitoring/cheque_book_monitoring/CanceledCheque'),
    meta: {
      title: 'Cheque Book Monitoring - Canceled',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/cheque-book-monitoring-monthly-used',
    name: 'cheque-book-monitoring-monthly-used',
    component: () => import('@/views/view_interface/monitoring/cheque_book_monitoring/MonthlyUsed'),
    meta: {
      title: 'Cheque Book Monitoring - Monthly Used',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/company-receivable-auto-loan',
    name: 'company-receivable-auto-loan',
    component: () => import('@/views/view_interface/monitoring/company_receivable/AutoLoan'),
    meta: {
      title: 'Company Receivable - AutoLoan',
      requiresAuth: true,
    },
  },
  {
    path: '/company-receivable-coop-loan',
    name: 'company-receivable-coop-loan',
    component: () => import('@/views/view_interface/monitoring/company_receivable/CoopLoan'),
    meta: {
      title: 'Company Receivable - CoopLoan',
      requiresAuth: true,
    },
  },
  {
    path: '/company-receivable-cash-advance',
    name: 'company-receivable-cash-advance',
    component: () => import('@/views/view_interface/monitoring/company_receivable/CashAdvance'),
    meta: {
      title: 'Company Receivable - Cash Advance',
      requiresAuth: true,
    },
  },
  {
    path: '/company-receivable-loan',
    name: 'company-receivable-loan',
    component: () => import('@/views/view_interface/monitoring/company_receivable/Loan'),
    meta: {
      title: 'Company Receivable - Loan',
      requiresAuth: true,
    },
  },
  {
    path: '/company-receivable-other-receivable',
    name: 'company-receivable-other-receivable',
    component: () => import('@/views/view_interface/monitoring/company_receivable/OtherReceivable'),
    meta: {
      title: 'Company Receivable - Other Receivable',
      requiresAuth: true,
    },
  },
  {
    path: '/company-receivable-office-sec-receivable',
    name: 'company-receivable-office-sec-receivable',
    component: () => import('@/views/view_interface/monitoring/company_receivable/SecDeposit'),
    meta: {
      title: 'Company Receivable - Office Rental Sec Deposit',
      requiresAuth: true,
    },
  },
  {
    path: '/company-receivable-motor-finance',
    name: 'company-receivable-motor-finance',
    component: () => import('@/views/view_interface/monitoring/company_receivable/MotorFinance'),
    meta: {
      title: 'Company Receivable - Motor Finance',
      requiresAuth: true,
    },
  },
  {
    path: '/company-receivable-bod-charge',
    name: 'company-receivable-bod-charge',
    component: () => import('@/views/view_interface/monitoring/company_receivable/BODCharges'),
    meta: {
      title: 'Company Receivable - BOD Charges',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/cash-voucher-report',
    name: 'cash-voucher-report',
    component: () => import('@/views/report_interface/vouchers/CashVoucher'),
    meta: {
      title: 'Cash Voucher Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
      requiresAMD: true,
    },
  },
  {
    path: '/cheque-voucher-report',
    name: 'cheque-voucher-report',
    component: () => import('@/views/report_interface/vouchers/ChequeVoucher'),
    meta: {
      title: 'Cheque Voucher Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresArAp: true,
      requiresCMD: true,
      requiresTreasury: true,
      requiresAMD: true,
    },
  },
  {
    path: '/fund-transfer-voucher-report',
    name: 'fund-transfer-voucher-report',
    component: () => import('@/views/report_interface/vouchers/FundTransferVoucher'),
    meta: {
      title: 'Fund Transfer Voucher Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },

  {
    path: '/cash-voucher-report-v2',
    name: 'cash-voucher-report-v2',
    component: () => import('@/views/report_interface/vouchers_v2/CashVoucher'),
    meta: {
      title: 'Cash Voucher Report V2',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
      requiresAMD: true,
    },
  },
  {
    path: '/cheque-voucher-report-v2',
    name: 'cheque-voucher-report-v2',
    component: () => import('@/views/report_interface/vouchers_v2/ChequeVoucher'),
    meta: {
      title: 'Cheque Voucher Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
      requiresAMD: true,
    },
  },
  {
    path: '/fund-transfer-voucher-report-v2',
    name: 'fund-transfer-voucher-report-v2',
    component: () => import('@/views/report_interface/vouchers_v2/FundTransferVoucher'),
    meta: {
      title: 'Fund Transfer Voucher Report V2',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/cash-voucher-monitoring',
    name: 'cash-voucher-monitoring',
    component: () => import('@/views/view_interface/monitoring/voucher_monitoring/CashVoucher'),
    meta: {
      title: 'Cash Voucher Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/fund-transfer-voucher-monitoring',
    name: 'fund-transfer-voucher-monitoring',
    component: () => import('@/views/view_interface/monitoring/voucher_monitoring/FundTransfer'),
    meta: {
      title: 'Fund Transfer Voucher Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/post-dated-cheque-monitoring',
    name: 'post-dated-cheque-monitoring',
    component: () => import('@/views/view_interface/monitoring/voucher_monitoring/PDC'),
    meta: {
      title: 'Post Dated Cheque Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/company-receivable-auto-loan-remittances',
    name: 'company-receivable-auto-loan-remittances',
    component: () => import('@/views/report_interface/company_receivable/AutoLoan'),
    meta: {
      title: 'Auto Loan - Company Receivable Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-cash-advance-remittances',
    name: 'company-receivable-cash-advance-remittances',
    component: () => import('@/views/report_interface/company_receivable/CashAdvance'),
    meta: {
      title: 'Cash Advance - Company Receivable Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-loan-remittances',
    name: 'company-receivable-loan-remittances',
    component: () => import('@/views/report_interface/company_receivable/Loan'),
    meta: {
      title: 'Loan - Company Receivable Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-other-receivable-remittances',
    name: 'company-receivable-other-receivable-remittances',
    component: () => import('@/views/report_interface/company_receivable/OtherReceivable'),
    meta: {
      title: 'Other Receivable - Company Receivable Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-office-sec-deposit-remittances',
    name: 'company-receivable-office-sec-deposit-remittances',
    component: () => import('@/views/report_interface/company_receivable/OfficeRentalSecDeposit'),
    meta: {
      title: 'Office Rental Sec Deposit - Company Receivable Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-motor-finance-remittances',
    name: 'company-receivable-motor-finance-remittances',
    component: () => import('@/views/report_interface/company_receivable/MotorFinance'),
    meta: {
      title: 'Motor Finance - Company Receivable Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-bod-charge-remittances',
    name: 'company-receivable-bod-charge-remittances',
    component: () => import('@/views/report_interface/company_receivable/BODCharge'),
    meta: {
      title: 'BOD Charge - Company Receivable Remittances',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/bod-annual-share-damayan',
    name: 'bod-annual-share-damayan',
    component: () => import('@/views/report_interface/bod_annual/BODDamayan'),
    meta: {
      title: 'BOD Annual Share - Damayan',
      requiresAuth: true,
      requiresConfidential: true,
    },
  },
  {
    path: '/bod-annual-share-chapel',
    name: 'bod-annual-share-chapel',
    component: () => import('@/views/report_interface/bod_annual/BODChapel'),
    meta: {
      title: 'BOD Annual Share - Chapel',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/deposit-slip-receivable',
    name: 'deposit-slip-receivable',
    component: () => import('@/views/input_interface/DepositSlipReceivable'),
    meta: {
      title: 'Deposit Slip for Receivable',
      requiresAuth: true,
      requiresAdmin: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-auto-loan-payment',
    name: 'company-receivable-auto-loan-payment',
    component: () => import('@/views/input_interface/company_receivable/AutoLoan'),
    meta: {
      title: 'Auto Loan - Payment Company Receivables',
      requiresAuth: true,
      requiresAdmin: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-cash-advance-payment',
    name: 'company-receivable-cash-advance-payment',
    component: () => import('@/views/input_interface/company_receivable/CashAdvance'),
    meta: {
      title: 'Cash Advance - Payment Company Receivables',
      requiresAuth: true,
      requiresAdmin: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-loan-payment',
    name: 'company-receivable-loan-payment',
    component: () => import('@/views/input_interface/company_receivable/Loan'),
    meta: {
      title: 'Loan - Payment Company Receivables',
      requiresAuth: true,
      requiresAdmin: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-other-receivable-payment',
    name: 'company-receivable-other-receivable-payment',
    component: () => import('@/views/input_interface/company_receivable/OtherReceivable'),
    meta: {
      title: 'Other Receivable - Payment Company Receivables',
      requiresAuth: true,
      requiresAdmin: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-motor-finance-payment',
    name: 'company-receivable-motor-finance-payment',
    component: () => import('@/views/input_interface/company_receivable/MotorFinance'),
    meta: {
      title: 'Motor Finance - Payment Company Receivables',
      requiresAuth: true,
      requiresAdmin: true,
      requiresArAp: true,
    },
  },
  {
    path: '/company-receivable-office-sec-payment',
    name: 'company-receivable-office-sec-payment',
    component: () => import('@/views/input_interface/company_receivable/OfficeRentalSecDeposit'),
    meta: {
      title: 'Office Rental Sec Deposit - Payment Company Receivables',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/company-receivable-bod-charge-payment',
    name: 'company-receivable-bod-charge-payment',
    component: () => import('@/views/input_interface/company_receivable/BODCharge'),
    meta: {
      title: 'BOD Charge - Payment Company Receivables',
      requiresAuth: true,
      requiresAdmin: true,

    },
  },
  {
    path: '/bussiness-permit-monitoring',
    name: 'bussiness-permit-monitoring',
    component: () => import('@/views/view_interface/monitoring/BusinessPermitMonitoring'),
    meta: {
      title: 'Business Permit Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBookKeeping: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/bussiness-permit-released-fund',
    name: 'bussiness-permit-released-fund',
    component: () => import('@/views/input_interface/business_permit/ReleasedFund'),
    meta: {
      title: 'Business Permit - Released Fund',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBookKeeping: true,
    },
  },
  {
    path: '/bussiness-permit-liquidation',
    name: 'bussiness-permit-liquidation',
    component: () => import('@/views/input_interface/business_permit/Liquidation'),
    meta: {
      title: 'Business Permit - Liquidation',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBookKeeping: true,
    },
  },
  {
    path: '/bussiness-permit-return-fund',
    name: 'bussiness-permit-return-fund',
    component: () => import('@/views/input_interface/business_permit/ReturnFund'),
    meta: {
      title: 'Business Permit - Return Fund',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBookKeeping: true,
    },
  },
  {
    path: '/security-deposit-office-rental',
    name: 'security-deposit-office-rental',
    component: () => import('@/views/input_interface/security_deposits/SecOfficeRental'),
    meta: {
      title: 'Security Deposit - Office/Chapel Rental',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/bod-income-monthly-allowance',
    name: 'bod-income-monthly-allowance',
    component: () => import('@/views/input_interface/bod_income/MonthlyAllowance'),
    meta: {
      title: 'BOD Income - Monthly Allowance',
      requiresAuth: true,
      requiresConfidential: true,
    },
  },
  {
    path: '/pre-liquidation-report',
    name: 'pre-liquidation-report',
    component: () => import('@/views/report_interface/PreLiquidationReport'),
    meta: {
      title: 'Pre Liquidation Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/monthly-income-report',
    name: 'monthly-income-report',
    component: () => import('@/views/report_interface/income/MonthlyRevenue'),
    meta: {
      title: 'Monthly Revenue Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
    },
  },
  {
    path: '/annual-trust-fund-report',
    name: 'annual-trust-fund-report',
    component: () => import('@/views/report_interface/income/AnnualTrustFund'),
    meta: {
      title: 'Annual Trust Fund Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
    },
  },
  {
    path: '/annual-sweep-fund-report',
    name: 'annual-sweep-fund-report',
    component: () => import('@/views/report_interface/income/AnnualSweepFund'),
    meta: {
      title: 'Annual Sweep Fund Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
    },
  },
  {
    path: '/annual-gross-revenue-report',
    name: 'annual-gross-revenue-report',
    component: () => import('@/views/report_interface/income/AnnualGrossRevenue'),
    meta: {
      title: 'Annual Gross Revenue Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
    },
  },
  {
    path: '/monthly-damayan-income-comparison-report',
    name: 'monthly-damayan-income-comparison-report',
    component: () => import('@/views/report_interface/income/MonthlyDamayanIncomeComparison'),
    meta: {
      title: 'Monthly Damayan Income Comparison Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
    },
  },
  {
    path: '/monthly-expenditure-report',
    name: 'monthly-expenditure-report',
    component: () => import('@/views/report_interface/expense/MonthlyExpenditure'),
    meta: {
      title: 'Monthly Expenditure Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/monthly-pdc-expenditure-report',
    name: 'monthly-pdc-expenditure-report',
    component: () => import('@/views/report_interface/expense/MonthlyPDCExpenditure'),
    meta: {
      title: 'Monthly PDC Expenditure Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/monthly-auto-deb-expenditure-report',
    name: 'monthly-auto-deb-expenditure-report',
    component: () => import('@/views/report_interface/expense/MonthlyAUTOExpenditure'),
    meta: {
      title: 'Monthly AUTO DEB Expenditure Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/annual-expenditure-report',
    name: 'annual-expenditure-report',
    component: () => import('@/views/report_interface/expense/AnnualExpenditure'),
    meta: {
      title: 'Annual Expenditure Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/annual-expenditure-monitoring-report',
    name: 'annual-expenditure-monitoring-report',
    component: () => import('@/views/report_interface/expense/AnnualExpenditureMonitoring'),
    meta: {
      title: 'Annual Expenditure Monitoring Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/search-voucher',
    name: 'search-voucher',
    component: () => import('@/views/file_interface/search/SearchVoucher'),
    meta: {
      title: 'Search Voucher',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
      requiresArAp: true,
      requiresCMD: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/new-affiliate',
    name: 'new-affiliate',
    component: () => import('@/views/file_interface/register/NewAffiliate'),
    meta: {
      title: 'New Affiliate',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-auto-maintenance-details',
    name: 'new-auto-maintenance-details',
    component: () => import('@/views/file_interface/register/AutoMaintenanceDetails'),
    meta: {
      title: 'New Auto Maintenance Details',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
    },
  },
  {
    path: '/new-company-inventory',
    name: 'new-company-inventory',
    component: () => import('@/views/file_interface/register/CompanyInventory'),
    meta: {
      title: 'New Company Inventory',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
    },
  },
  {
    path: '/load-equipment-inventory-stocks',
    name: 'load-equipment-inventory-stocks',
    component: () => import('@/views/load_interface/EquipmentInventoryStocks'),
    meta: {
      title: 'Load Equipment Inventory Stocks',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
    },
  },
  {
    path: '/load-car-inventory-stocks',
    name: 'load-car-inventory-stocks',
    component: () => import('@/views/load_interface/CarInventoryStocks'),
    meta: {
      title: 'Load Car Inventory Stocks',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
    },
  },
  {
    path: '/new-request-inventory',
    name: 'new-request-inventory',
    component: () => import('@/views/file_interface/request_inventory/RequestInventory'),
    meta: {
      title: 'Request Inventory',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
    },
  },
  {
    path: '/inventory-monitoring-building',
    name: 'inventory-monitoring-building',
    component: () => import('@/views/view_interface/inventory_monitoring/Building'),
    meta: {
      title: 'Inventory Monitoring Building',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/inventory-monitoring-land',
    name: 'inventory-monitoring-land',
    component: () => import('@/views/view_interface/inventory_monitoring/Land'),
    meta: {
      title: 'Inventory Monitoring Land',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/inventory-monitoring-car',
    name: 'inventory-monitoring-car',
    component: () => import('@/views/view_interface/inventory_monitoring/Car'),
    meta: {
      title: 'Inventory Monitoring Car',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/inventory-monitoring-equipment',
    name: 'inventory-monitoring-equipment',
    component: () => import('@/views/view_interface/inventory_monitoring/Equipment'),
    meta: {
      title: 'Inventory Monitoring Equipment',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
      requiresFinance: true,
      requiresCMD: true,
    },
  },
  {
    path: '/ml-department',
    name: 'ml-department',
    component: () => import('@/views/input_interface/monthly_liquidation/components/Department'),
    meta: {
      title: 'Department Liquidation',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/new-user',
    name: 'new-user',
    component: () => import('@/views/admin/NewUser'),
    meta: {
      title: 'New User of System',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-tie_up',
    name: 'new-tie_up',
    component: () => import('@/views/file_interface/register/NewTieUp'),
    meta: {
      title: 'New Tie Up',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAMD: true,
    },
  },
  {
    path: '/new-cofp-accounts',
    name: 'new-cofp-accounts',
    component: () => import('@/views/file_interface/register/NewCOFPAccounts'),
    meta: {
      title: 'New COFP Accounts',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAMD: true,
    },
  },
  {
    path: '/auto-maintenance-monitoring',
    name: 'auto-maintenance-monitoring',
    component: () => import('@/views/view_interface/AutoMaintenanceMonitoring'),
    meta: {
      title: 'Auto Maintenance Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAMD: true,
      requiresProperty: true,
    },
  },
  {
    path: '/payslip-report',
    name: 'payslip-report',
    component: () => import('@/views/view_interface/salaries_wages/PaySlip'),
    meta: {
      title: 'Payslip',
      requiresAuth: true,
    },
  },
  {
    path: '/13th-monthpayslip-report',
    name: '13th-monthpayslip-report',
    component: () => import('@/views/view_interface/salaries_wages/ThirtenPaySlip'),
    meta: {
      title: '13th Month Payslip',
      requiresAuth: true,
    },
  },
  {
    path: '/14th-monthpayslip-report',
    name: '14th-monthpayslip-report',
    component: () => import('@/views/view_interface/salaries_wages/FourteenPaySlip'),
    meta: {
      title: '14th Month Payslip',
      requiresAuth: true,
    },
  },
  {
    path: '/pending-salary-increase-report',
    name: 'pending-salary-increase-report',
    component: () => import('@/views/view_interface/employee/PendingSalaryIncreaseReport'),
    meta: {
      title: 'Pending Salary Increase Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresHR: true,
    },
  },
  {
    path: '/search-inventory',
    name: 'search-inventory',
    component: () => import('@/views/file_interface/search/SearchInventory'),
    meta: {
      title: 'Search Inventory',
      requiresAuth: true,
      requiresAdmin: true,
      requiresProperty: true,
    },
  },
  {
    path: '/lot-rental-monitoring',
    name: 'lot-rental-monitoring',
    component: () => import('@/views/view_interface/billing_monitoring/LotRental'),
    meta: {
      title: 'Lot Rental Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/bill-electric-monitoring',
    name: 'bill-electric-monitoring',
    component: () => import('@/views/view_interface/billing_monitoring/ElectricBill'),
    meta: {
      title: 'Electric Bill Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/list-of-bank-depository',
    name: 'list-of-bank-depository',
    component: () => import('@/views/dashboard/ListOfBankDepositories'),
    meta: {
      title: 'List Of Bank Depositories',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/allocation-of-fund',
    name: 'allocation-of-fund',
    component: () => import('@/views/admin/board_monitoring/AllocationOfFund'),
    meta: {
      title: 'Allocation Of Fund',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/bill-water-monitoring',
    name: 'bill-water-monitoring',
    component: () => import('@/views/view_interface/billing_monitoring/WaterBill'),
    meta: {
      title: 'Water Bill Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/bill-office-monitoring',
    name: 'bill-office-monitoring',
    component: () => import('@/views/view_interface/billing_monitoring/OfficeRental'),
    meta: {
      title: 'Office Rental Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/bill-staff-monitoring',
    name: 'bill-staff-monitoring',
    component: () => import('@/views/view_interface/billing_monitoring/StaffHouse'),
    meta: {
      title: 'Staff House Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/personal-information',
    name: 'personal-information',
    component: () => import('@/views/dashboard/EmployeeInformation'),
    meta: {
      title: 'Your Information"s',
      requiresAuth: true,
    },
  },
  {
    path: '/list-of-service',
    name: 'list-of-service',
    component: () => import('@/views/view_interface/amd_monitoring/ListOfService'),
    meta: {
      title: 'List Of Service',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAMD: true,
    },
  },
  {
    path: '/list-of-release-claims',
    name: 'list-of-release-claims',
    component: () => import('@/views/view_interface/amd_monitoring/ListOfReleaseClaims'),
    meta: {
      title: 'List Of Release Claims',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAMD: true,
    },
  },
  {
    path: '/department-fund-monitoring',
    name: 'department-fund-monitoring',
    component: () => import('@/views/view_interface/monitoring/fund_monitoring/components/RevolvingFund'),
    meta: {
      title: 'Department Fund Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresEmployee: true,
      requiresAccounting: true,
      requiresFinance: true,
    },
  },
  {
    path: '/sales-drive-monitoring',
    name: 'sales-drive-monitoring',
    component: () => import('@/views/view_interface/monitoring/fund_monitoring/components/SalesDrive'),
    meta: {
      title: 'Sales Drive Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresEmployee: true,
      requiresAccounting: true,
    },
  },
  {
    path: '/daily-deposit-bank-report',
    name: 'daily-deposit-bank-report',
    component: () => import('@/views/report_interface/income/DailyDeposited'),
    meta: {
      title: 'Daily Deposit Bank Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
    },
  },
  {
    path: '/list-of-memos',
    name: 'list-of-memos',
    component: () => import('@/views/user_interface/memos/ListOfMemos'),
    meta: {
      title: 'List Of Memos',
      requiresAuth: true,
    },
  },
  {
    path: '/ml-damayan-revolving-fund',
    name: 'ml-damayan-revolving-fund',
    component: () => import('@/views/input_interface/monthly_liquidation/components/DamayanRF'),
    meta: {
      title: 'Damayan RF Liquidations',
      requiresAuth: true,
      requiresEmployee: true,
    },
  },
  {
    path: '/ml-damayan-sales-drive-fund',
    name: 'ml-damayan-sales-drive-fund',
    component: () => import('@/views/input_interface/monthly_liquidation/components/DamayanSD'),
    meta: {
      title: 'Damayan Sales Drive Liquidations',
      requiresAuth: true,
      requiresEmployee: true,
    },
  },
  {
    path: '/ml-chapel-revolving-fund',
    name: 'ml-chapel-revolving-fund',
    component: () => import('@/views/input_interface/monthly_liquidation/components/ChapelRF'),
    meta: {
      title: 'Chapel Fund Liquidations',
      requiresAuth: true,
      requiresEmployee: true,
    },
  },
  {
    path: '/ml-damayan-revolving-fund-v2',
    name: 'ml-damayan-revolving-fund-v2',
    component: () => import('@/views/input_interface/monthly_liquidation_v2/components/DamayanRF'),
    meta: {
      title: 'Damayan RF Liquidations V2',
      requiresAuth: true,
    },
  },
  {
    path: '/ml-damayan-sales-drive-fund-v2',
    name: 'ml-damayan-sales-drive-fund-v2',
    component: () => import('@/views/input_interface/monthly_liquidation_v2/components/DamayanSD'),
    meta: {
      title: 'Damayan Sales Drive Liquidations V2',
      requiresAuth: true,
    },
  },
  {
    path: '/ml-chapel-revolving-fund-v2',
    name: 'ml-chapel-revolving-fund-v2',
    component: () => import('@/views/input_interface/monthly_liquidation_v2/components/ChapelRF'),
    meta: {
      title: 'Chapel Fund Liquidations V2',
      requiresAuth: true,
    },
  },
  {
    path: '/bank-trial-and-balance-report',
    name: 'bank-trial-and-balance-report',
    component: () => import('@/views/admin/board_monitoring/BankTialAndBalance'),
    meta: {
      title: 'Bank Trial & Balance',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
      requiresFinance: true,
    },
  },
  {
    path: '/new-position',
    name: 'new-position',
    component: () => import('@/views/file_interface/register/NewPosition'),
    meta: {
      title: 'New Position',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/liquidations-maintenance',
    name: 'liquidations-maintenance',
    component: () => import('@/views/report_interface/expense/MonthlyExpenditure'),
    meta: {
      title: 'Liquidations Maintenance',
      requiresAuth: true,
      requiresAdmin: true,
      requiresAccounting: true,
      requiresFinance: true,
    },
  },
  {
    path: '/send-memo',
    name: 'send-memo',
    component: () => import('@/views/admin/board_monitoring/SendMemo'),
    meta: {
      title: 'Send Memo',
      requiresAuth: true,
      requiresAdmin: true,
      requiresExecutive: true,
    },
  },
  {
    path: '/damayan-incentives-monitoring',
    name: 'damayan-incentives-monitoring',
    component: () => import('@/views/view_interface/monitoring/DamayanIncentivesMonitoring'),
    meta: {
      title: 'Damayan Incentives Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-request-for-final-approval',
    name: 'list-of-request-for-final-approval',
    component: () => import('@/views/file_interface/create/request/ListOfRequestForFinalApproval'),
    meta: {
      title: 'List Of Request For Final Approval',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-for-approval-voucher-report',
    name: 'list-for-approval-voucher-report',
    component: () => import('@/views/report_interface/vouchers/ListOfVoucherForApproval'),
    meta: {
      title: 'List Of Voucher For Approval',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/list-for-approval-voucher-report-v2',
    name: 'list-for-approval-voucher-report-v2',
    component: () => import('@/views/report_interface/vouchers_v2/ListOfVoucherForApproval'),
    meta: {
      title: 'List Of Voucher For Approval V2',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTreasury: true,
    },
  },
  {
    path: '/minimum-wages-per-region',
    name: 'minimum-wages-per-region',
    component: () => import('@/views/file_interface/create/MinimumWagesOfRegion'),
    meta: {
      title: 'List of Minimum Wages per Region',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-request-credited',
    name: 'list-of-request-credited',
    component: () => import('@/views/user_interface/ListOfRequestCredited'),
    meta: {
      title: 'List O fRequest Credited',
      requiresAuth: true,
    },
  },
  {
    path: '/branch-directory',
    name: 'branch-directory',
    component: () => import('@/views/view_interface/BranchDirectory'),
    meta: {
      title: 'Branch Directory',
      requiresAuth: true,
    },
  },
  {
    path: '/search-employee-payables',
    name: 'search-employee-payables',
    component: () => import('@/views/file_interface/search/SearchEmployeePayables'),
    meta: {
      title: 'Search Employee Payables',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/monthly-bank-interest-report',
    name: 'monthly-bank-interest-report',
    component: () => import('@/views/report_interface/income/MonthlyInterestReport'),
    meta: {
      title: 'Monthly Bank Interest Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCMD: true,
    },
  },
  {
    path: '/new-business-information',
    name: 'new-business-information',
    component: () => import('@/views/ggc_loan_interface/register/NewBusiness'),
    meta: {
      title: 'New Business',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
      requiresArAp: true,
    },
  },
  {
    path: '/head-office-evaluation',
    name: 'head-office-evaluation',
    component: () => import('@/views/dashboard/MonthlyEvaluationEmployee'),
    meta: {
      title: 'Head Office Monthly Evaluation',
      requiresAuth: true,
    },
  },

  {
    path: '/new-particulars-version-2',
    name: 'new-particulars-version-2',
    component: () => import('@/views/file_interface/register/NewParticularsV2'),
    meta: {
      title: 'New Particulars v2',
      requiresAuth: true,
      requiresAdmin: true,
      requiresFinance: true,
    },
  },
]

const router = new VueRouter({
  // mode: "history",
  routes,
})

export default router
