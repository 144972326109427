import axios from 'axios'

axios.defaults.withCredentials = true

const actions = {
  check_availability_fourteen_month_for_request({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/check_availability_fourteen_month_for_request', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initialize_fourteen_month_payroll({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/initialize_fourteen_month_payroll', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create_fourteen_month_pay({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/create_fourteen_month_pay', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  list_of_pending_fourteen_month_pay({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/list_of_pending_fourteen_month_pay', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update_fourteen_month_pay({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/update_fourteen_month_pay', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fourteen_month_pay_approved({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/fourteen_month_pay_approved', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fourteen_payslip_data({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/fourteen_payslip_data', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
}
