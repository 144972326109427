<template>
  <v-snackbars
    :messages.sync="messages"
    :color=color
    top
    right
  ></v-snackbars>
</template>

<script>
  import {mapActions} from 'vuex'
  import VSnackbars from "v-snackbars";

  export default {
    components: {
      "v-snackbars": VSnackbars,
    },
    data() {
      return {
        messages: [],
      }
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
    },
    watch: {
      snackbar_flag: function () {
        this.messages.push(this.snackbar_text);
      },
    },
    props: {
      snackbar_flag: Boolean,
      color: String,
      snackbar_text: String,
    },
  }
</script>
